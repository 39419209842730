<template>
  <div>
    <Navbar/>
  <div class="auth-wrapper">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
     
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center "
        style="margin-top: -8rem; margin-left: -4rem;"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 prime-blue-background"
        >
          <b-img
            fluid
            src="../../assets/yicimages/register/HumaaansWireframe2.png"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <div v-if="alreadyVerified" class="font-weight-bold mb-1">
              You have already verified your email. PLease continue to login.
              <br />
              <b-button
                variant="outline-primary"
                class="CTL"
                v-on:click="continueToLogin"
              >
                Login</b-button
              >
            </div>
            <div v-else-if="verifying" class="font-weight-bold mb-1">
              Please wait while we verify your email...
            </div>
            <div v-else class="font-weight-bold mb-1 status">
              You have been successfully verified
              <br />
              <!-- <div class="loginButton" @click="continueToLogin">Login</div> -->
              <!-- <b-button variant="outline-primary" class="CTL" @click="continueToLogin">Login</b-button> -->
              <b-button
                variant="outline-primary"
                class="CTL"
                v-on:click="continueToLogin"
              >
                Login</b-button
              >
            </div>
            <b-spinner
              v-if="verifying"
              style="width: 3rem; height: 3rem; float: right"
              class="mr-1"
              label="Large Spinner"
            />
          </b-card-title>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <RegisterVirtualAddress ref="rva"></RegisterVirtualAddress>
  </div>
</div>
</template>
<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapActions, mapState } from "vuex";
import Navbar from '../navbar/Navbar.vue'
import RegisterVirtualAddress from "./RegisterVirtualAddress.vue";
// const { AuthenticationManagementService } = require('feathers-authentication-management');
// app.use('auth-management', new AuthenticationManagementService(app, {}));

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BSpinner,
} from "bootstrap-vue";
import feathersClient from "../../feathers-client";
export default {
  // props: ['token'],
  components: {
    Navbar,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    RegisterVirtualAddress,
  },
  name: "VerifyEmail",
  data() {
    return {
      verifying: true,
      alreadyVerified: false,
    };
  },
  computed: {
    ...mapState({
      warehouseAddress: (state) => state.emailVerification.warehouseAddress,
      isAlreadyVerified: (state) => state.emailVerification.alreadyVerified,
    }),
  },
  methods: {
    ...mapActions({
      verifyYourEmail: "emailVerification/verifyYourEmail",
      checkIfAlreadyVerified: "emailVerification/checkIfAlreadyVerified",
    }),
    continueToLogin() {
      console.log("Continue to");
      this.$router.push("/login");
    },
  },
  async mounted() {
    try {
      console.log(this.$route.params.token);
      await this.verifyYourEmail({ token: this.$route.params.token });
      if (this.isAlreadyVerified) {
        this.alreadyVerified = true;
        this.verifying = false;
      } else {
        this.verifying = false;
        const arr = this.warehouseAddress.split("#");
        this.$refs.rva.showModal(arr);
      }
    } catch (e) {
      console.log("E : " + e);
    }
  },
};
</script>
<style>
.prime-blue-background {
  background-color: #4385f5;
}
select option {
  margin: 40px;
  background-color: white;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.loginButton {
  font-size: 25px;
  display: block;
  outline: 1px solid #4385f5;
  width: 20%;
}
.status {
  text-align: center;
}

.CTL {
  margin-top: 5%;
}
</style>