<template>
  <div>
  <div>
    <b-modal
      ref="rva"
      id="modal-center"
      centered
      v-model="modalShow"
      hide-footer
      hide-header
      size="lg"
      body-class="myClass"
    >
      <div class="root">
        <div class="left-side">
          <div class="title">You can use your virtual address to :</div>
          <div v-for="(e, i) in list" :key="i" class="list-items">
            <span class="icon">
              <img :src="e.src" alt="icons" />
            </span>
            <span>{{ e.description }}</span>
          </div>
        </div>
        <div class="right-side">
          <div class="Congratulations">Congratulations!!🎉</div>
          <div class="text-box">
            <div>{{address[0]}}</div>
            <div>{{address[1]}}</div>
            <div>{{address[2]}} {{address[3]}}</div>
          </div>
          <div>
            This is your virtual
            <span class="blue"> Indian warehouse address </span>
          </div>
          <div class="buttons">
            <b-button
              class="bttn1"
              variant="no-color"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="close"
            >
              Close
            </b-button>
            <b-button
              class="bttn2"
              variant="no-color"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="copy"
            >
              Copy Address
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>
<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import trolley from "../../assets/yicimages/register/trolley.png";
import aeroplane from "../../assets/yicimages/register/aeroplane.png";
import building from "../../assets/yicimages/register/building.png";
import Navbar from '../navbar/Navbar.vue'
export default {
  props: ["open"],
  components: {
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    Navbar,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  data() {
    return {
      modalShow: this.open,
      // address:
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.",
      list: [
        {
          description: "Deliver your e-commerce purchases",
          src: trolley,
        },
        {
          description:
            "Shipping packages from your virtual address to your doorstep",
          src: aeroplane,
        },
        {
          description: "Store packages at our warehouses.",
          src: building,
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setRegisteredUser: "register/SET_REGISTERED_USER",
    }),
    copy() {
      navigator.clipboard.writeText(this.address);
    },
    close() {
      this.modalShow = false;
      this.$router.push({ name: "login" });
    },
    showModal(addressData) {
      this.setRegisteredUser(addressData);
      this.$refs.rva.show();
    },
  },
  computed: mapState({
    address: (state) => {
      if (state.register.registeredUser)
        return state.register.registeredUser;
      return "";
    },
  }),
};
</script>
<style scoped>
.root {
  display: grid;
  grid-template-columns: 1fr 1.35fr;
}
.left-side {
  padding: 3rem 2rem;
  padding-top: 4rem;
  background: #4385f5;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 16px;
}
.list-items {
  font-size: 13px;
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
}
.right-side {
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 500;
  font-size: 16px;
}
.Congratulations {
  font-weight: 600;
  font-size: 25px;
  /* line-height: 51px; */
  color: #000000;
}
.blue {
  color: #4385f5;
}
.text-box {
  border: 1px solid #c0c0c0;
  padding: 1rem;
  height: 160px;
  font-size: 17px;
}
/* .modal-body{
  padding: 0 !important;
} */
.bttn1 {
  background: #d9e7fd;
  color: #4385f5;
  min-width: 160px;
}
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.icon {
  background: #d9e7fd;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
}
</style>
<style>
.myClass {
  padding: 0;
}
</style>